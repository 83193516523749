import axios from 'axios';
import { baseAPI } from '../constants/functions';

var headers = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET"
};

const ReportsCallInit = async (data) => {
    try {
        let res = await axios({
             url: baseAPI.active + '/report/init',
             method: 'POST',
             data: data,
             port: 443,
             timeout: 8000,
             headers: headers,
         })
         if(res.status === 200){
             //success
             //console.log(res.data.bodyJSON.formData.id);
         }
         return res.data
     }
     catch (err) {
         console.error(err);
     }
}

const ReportsCallGet = async (data) => {
    try {
        let res = await axios({
             url: baseAPI.active + '/report/get',
             method: 'POST',
             data: data,
             port: 443,
             timeout: 8000,
             headers: headers,
         })
         if(res.status === 200){
             //success
             //console.log(res.data.bodyJSON.formData.id);
         }
         return res.data
     }
     catch (err) {
         console.error(err);
     }
}

const ReportsCallIssue = async (data) => {
    try {
        let res = await axios({
             url: baseAPI.active + '/report/issue',
             method: 'POST',
             data: data,
             port: 443,
             timeout: 8000,
             headers: headers,
         })
         if(res.status === 200){
             //success
             //console.log(res.data.bodyJSON.formData.id);
         }
         return res.data
     }
     catch (err) {
         console.error(err);
     }
}

export { ReportsCallInit, ReportsCallGet, ReportsCallIssue };