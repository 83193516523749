import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Protect from 'react-app-protect';
import './index.css';
import * as serviceWorker from './serviceWorker';

/*
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
    gtmId: 'G-H2NF94SMNX'
};
TagManager.initialize(tagManagerArgs);
*/

/*
      <Protect sha512='51fbc05c62ecd901ebedf4c21f07772c18cb4c8e27ba271a1be88d57c3f2908aaf9aa84ba922fbfb306ad0fc088f33145f6a834d39c81b8e9d08e86ce92ecdd6'>
        <App />
      </Protect>
*/

ReactDOM.render(
  <StrictMode>
      <App />
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
