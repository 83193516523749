import React from 'react';
import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import {
    TextField,
    Grid,
    Typography,
    Divider,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";


import { useStateValue } from "../../../StateContext";
import PaymentSummary from './FormComponents/PaymentSummary';

const dividerSection = {
    height: "25px"
};

const DarkerDisabledTextField = withStyles({
    root: {
      marginRight: 8,
      "& .MuiInputBase-root.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.9)" // (default alpha is 0.38)
      }
    }
})(TextField);

const ApplicantComplete = () => {

    const [{ formValues }] = useStateValue();

    return <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            
            <Grid item xs={12}>
                <Typography variant="h6">Thank You!</Typography>
                <Typography>Thank you for using Qualofied!  You will be receiving a copy of this in your email.  Please check your spam/ junk folder and print this page for future referrence.  The email will be coming from info@qualofied.com.</Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h6">Order Summary</Typography>
            </Grid>

            <Grid item xs={12}>
                <PaymentSummary />
            </Grid>

            <Grid item xs={12}>           
                <div style={dividerSection}></div>
                <Divider orientation="horizontal" />
                <div style={dividerSection}></div>
                <Typography variant="h6">Order Details</Typography>
            </Grid>
            
            <Grid item xs={12} sm={6}>
                <DarkerDisabledTextField
                    label="Customer ID"
                    name="order_transaction_customer"
                    required
                    fullWidth
                    value={formValues.order_transaction_customer}
                    disabled={true}
                />
            </Grid>

            <Grid item xs={12} sm={3}>
                <DarkerDisabledTextField
                    label="Order Transaction"
                    name="order_transaction_id"
                    required
                    fullWidth
                    value={formValues.order_transaction_id}
                    disabled={true}
                />
            </Grid>

            <Grid item xs={12} sm={3}>
                <DarkerDisabledTextField
                    label="Amount Paid"
                    name="order_transaction_amount"
                    required
                    fullWidth
                    value={formValues.order_transaction_amount}
                    disabled={true}
                />
            </Grid>

            <Grid item xs={12}>
                <div style={dividerSection}></div>
                <Divider orientation="horizontal" />
                <div style={dividerSection}></div>
                <Typography variant="h6">Report Recipients</Typography>
                <Typography>A copy of the credit report will be sent to the following names and emails listed below.</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
                <DarkerDisabledTextField
                    label="Your Full Name"
                    name="information_applicant_firstname"
                    required
                    fullWidth
                    disabled={true}
                    value={formValues.information_applicant_firstname + ' ' + formValues.information_applicant_middlename + '. ' + formValues.information_applicant_lastname}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DarkerDisabledTextField
                    label="Your Email Address"
                    name="information_applicant_email"
                    required
                    fullWidth
                    value={formValues.information_applicant_email}
                    disabled={true}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <DarkerDisabledTextField
                    label="Requesting Landlord Full Name"
                    name="information_landlord_fullname"
                    required
                    fullWidth
                    value={formValues.information_landlord_fullname}
                    disabled={true}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DarkerDisabledTextField
                    label="Requesting Landlord Email Address"
                    name="information_landlord_email"
                    required
                    fullWidth
                    value={formValues.information_landlord_email}
                    disabled={true}
                />
            </Grid>

        </MuiPickersUtilsProvider>
    </>
}

export default ApplicantComplete;
