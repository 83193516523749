import React from 'react';
import {
  Link
} from "react-router-dom";

import {
  Box,
  Container,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles, } from '@material-ui/core/styles';
import { getEnivornment } from '../constants/functions';

import Header from "./Header";

const useStyles = makeStyles(theme => ({
  boxWrapper: {
      marginBottom: "55px",
      minHeight: "calc(26vh + 260px)"
  },
  container: {
      position: "relative",
      zIndex: "1100",
      marginTop: "-95px",
      marginBottom: "45px",
  },
  mainBox: {
    position: "relative",
    marginTop: "-8px",
    padding: "10px 20px",
    borderBottomRightRadius: "4px",
    borderBottomLeftRadius: "4px",
    background: theme.palette.background.default
  },
}));

export default function Home() {
  const classes = useStyles();

  const env = getEnivornment();
  console.log('getEnivornment', getEnivornment());
  const stage = 'stage';

  const StageContent = () => {
    if (env === stage) {
        return <>
            <Typography>
                <strong>You are viewing the stage version of this site.  You may only apply with the information allowed.</strong>
                <br></br>
            </Typography>
        </>
    }
  };

  const HomeContent = () => {
      return <>
          <Typography>
              <br></br>
              Qualofied allows you to keep track of your tenant screening application for DHA properties.
              <br></br><br></br>
              To begin, complete and submit your rental application here: <Link to="/application">application form here</Link>.  Once you submit your application, you will receive an email link to your secure credit report.  Please note that your link will expire after 24 hours.
              <br></br><br></br>
              If you completed an application and your link has expired, request your report by completing the <Link to="/reports">report request form here</Link>.
              <br></br><br></br>
              The credit report request will result in a soft hit and not affect your FICO (Fair Isaac Corporation) or Vantage credit score.  The cost you will be charged for this request is $25.00.
              <br></br><br></br>
              Please contact us at info@qualofied.com with any questions.
          </Typography>
      </>
  };

  return (
    <div>
      <Header title="WELCOME TO QUALOFIED!" />
      <Box component="main" className={classes.boxWrapper}>
          <Container maxWidth="md" className={classes.container}>
            <Paper elevation={5}>
              <Box className={classes.mainBox}>
                {StageContent()}
                {HomeContent()}
              </Box>
            </Paper>
          </Container>
        </Box>
    </div>
  );

}