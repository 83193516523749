import React from "react";
import DropIn from "braintree-web-drop-in-react";
import { useStateValue } from "../../../../StateContext";
import { BraintreePostToken, BraintreePostPayment } from '../../../../ServerCalls/BraintreeCalls';
import { TazworksPostOrder } from '../../../../ServerCalls/TazworksCalls';
import commonData from '../../../../data/common.json';

import {
    Grid,
    Button,
} from "@material-ui/core";

class BraintreePaymentFormImpl extends React.Component {
  instance; 
  state = {
    clientToken: null,
    options: { authorization: null },
    message: ''
  };

  async componentDidMount() {

    const data = {
        customer: {
            id: this.props.formValues.id,
            application_id: this.props.formValues.application_id,
            firstName: this.props.formValues.information_applicant_firstname,
            lastName: this.props.formValues.information_applicant_lastname,
            email: this.props.formValues.information_applicant_email,
            phone: this.props.formValues.information_applicant_cellphonenumber
        }
    }

    const res = await BraintreePostToken(data);

    if(res.status === 200){
        const clientToken = await res.data;
        this.setState({
            options: {
                authorization: clientToken,
                dataCollector: true,
                card: {
                    cardholderName: { required: true }
                },
                fields: commonData.braintreeCardData
            },
            clientToken: clientToken
        });
    }
  };
 
  async buy() {
    const payment = await this.instance.requestPaymentMethod();
    const data = {
        payment: payment,
        content: {
            customer_id: this.props.formValues.id,
            application_id: this.props.formValues.application_id,
            code: this.props.formValues.information_landlord_referral,
            products: this.props.formValues.services_products,
        }
    }

    const res = await BraintreePostPayment(data);
    
    if(res.status === 200){
        if (res.data.success) {
            //console.log(res.data);
            this.props.handlePayment(res);
            
            data.content.transaction = res.data.transaction.id;
            this.processOrder(data.content);

            this.setState({
                message: 'Payment Successful!'
            });
        } else {
            this.setState({
                message: 'Payment Failed!'
            });
        }
    }
  }

  async processOrder(data) {
    const res = await TazworksPostOrder(data);
    console.log(res);
    /*

    const orderData = {
        application_id: data.application_id,
        customer_id: data.customer_id,
        order_guid: res.data.orderData.order_guid
    }
    */
  }

  displayContent = () => {
      if (this.props.formValues.payment_complete) {
        return (
            <div>
                {this.state.message}
            </div>
        );
      } else {
        return (
            <DropIn
                options={this.state.options}
                onInstance={
                    (instance) => (this.instance = instance)
                }
            />
        );
      }
  };

  render() {
    if (!this.state.clientToken) {
      return (
        <div>
          <h1>Loading...</h1>
        </div>
      );
    } else {
      return (
        <div>  
            {this.displayContent()}
            <Grid item xs={12} container alignContent="center" alignItems="center" justify="center">            
                <Button
                    variant="contained"
                    color="primary"
                    disabled={this.props.formValues.payment_complete}
                    onClick={this.buy.bind(this)}
                >
                    Complete Payment
                </Button>
            </Grid>
        </div>
      );
    }
  }
}


const BraintreePaymentForm = () => {
    const [{ formValues }, dispatch] = useStateValue();

    const handlePayment = (res) => {
        dispatch({
            type: 'editFormValue',
            key: "payment_complete",
            value: true
        });

        dispatch({
            type: 'editFormValue',
            key: "order_transaction_id",
            value: res.data.transaction.id
        });

        dispatch({
            type: 'editFormValue',
            key: "order_transaction_amount",
            value: res.data.transaction.amount
        });

        dispatch({
            type: 'editFormValue',
            key: "order_transaction_customer",
            value: res.data.transaction.customer.id
        });
        //console.log(res);
    };
    return (
        <BraintreePaymentFormImpl formValues={formValues} handlePayment={handlePayment}> </BraintreePaymentFormImpl>
    );
};

export default BraintreePaymentForm;