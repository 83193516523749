import React from 'react';
import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
    TextField,
    Grid,
    Typography,
    InputLabel,
    Divider,
    Input,
    FormControl
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStateValue } from "../../../StateContext";
import { TextInputPhone } from './FormComponents/TextInputPhone';
import NumberFormatAmount from './FormComponents/NumberFormatAmount';
import commonData from '../../../data/common.json';

const dividerSection = {
    height: "25px"
};

const ApplicantIncomeForm = () => {

    const [{ formValues }, dispatch] = useStateValue();
    
    /* BACKUP: <MuiPickersUtilsProvider utils={MomentUtils}> */
    return <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            
            <Grid item xs={12}>
                <Typography variant="h6">Current Income</Typography>
                <Typography>Your current employer and income</Typography>
            </Grid>
            
            <Grid item xs={12}>
                <TextField
                    label="Employer's Individual/Company Name"
                    name="income_current_companyname"
                    required
                    fullWidth
                    value={formValues.income_current_companyname}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "income_current_companyname",
                            value: e.target.value
                        })
                    }
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <TextField
                    label="City"
                    name="income_current_companycity"
                    required
                    fullWidth
                    value={formValues.income_current_companycity}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "income_current_companycity",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Autocomplete
                    options={commonData.states}
                    getOptionLabel={option => option.name}
                    renderInput={params =>
                        <TextField
                            label="State"
                            name="income_current_companystate"
                            required
                            fullWidth
                            {...params}
                        />
                    }
                    value={formValues.income_current_companystate}
                    onChange={(event, value) => {
                        dispatch({
                            type: 'editFormValue',
                            key: "income_current_companystate",
                            value: value
                        })
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    label="Postal Code"
                    name="income_current_companyzipcode"
                    required
                    fullWidth
                    value={formValues.income_current_companyzipcode}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "income_current_companyzipcode",
                            value: e.target.value
                        })
                    }
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                    id="income_current_dateemployedfrom"
                    label="Date Employed From"
                    name="income_current_dateemployedfrom"
                    required
                    fullWidth
                    format="MM/dd/yyyy"
                    value={formValues.income_current_dateemployedfrom}
                    onChange={date =>
                        dispatch({
                            type: 'editFormValue',
                            key: "income_current_dateemployedfrom",
                            value: date
                        })
                    }
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                    id="income_current_dateemployedto"
                    label="Date Employed To"
                    name="income_current_dateemployedto"
                    required
                    fullWidth
                    format="MM/dd/yyyy"
                    value={formValues.income_current_dateemployedto}
                    onChange={date =>
                        dispatch({
                            type: 'editFormValue',
                            key: "income_current_dateemployedto",
                            value: date
                        })
                    }
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    label="Supervisor's Name"
                    name="income_current_companysupervisorname"
                    required
                    fullWidth
                    value={formValues.income_current_companysupervisorname}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "income_current_companysupervisorname",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth={true} required={true}>
                    <InputLabel htmlFor="standard">Supervisor's Phone</InputLabel>
                    <Input
                        label="Supervisor's Phone"
                        inputComponent={TextInputPhone}
                        name="income_current_companysupervisorphonenumber"
                        value={formValues.income_current_companysupervisorphonenumber}
                        onChange={e =>
                            dispatch({
                                type: 'editFormValue',
                                key: "income_current_companysupervisorphonenumber",
                                value: e.target.value
                            })
                        }
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    label="Your Position/Title"
                    name="income_current_companyposition"
                    required
                    fullWidth
                    value={formValues.income_current_companyposition}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "income_current_companyposition",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Salary/Wage"
                    name="income_current_companysalary"
                    id="income_current_companysalary"
                        InputProps={{
                        inputComponent: NumberFormatAmount,
                    }}
                    required
                    fullWidth
                    value={formValues.income_current_companysalary}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "income_current_companysalary",
                            value: e.target.value
                        })
                    }
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <Autocomplete
                    options={commonData.binary}
                    getOptionLabel={option => option.name}
                    renderInput={params =>
                        <TextField
                            label="Other Source of Income?"
                            name="income_current_otherincome"
                            fullWidth
                            {...params}
                        />
                    }
                    value={formValues.income_current_otherincome}
                    onChange={(event, value) => {
                        dispatch({
                            type: 'editFormValue',
                            key: "income_current_otherincome",
                            value: value
                        })
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Monthly Amount"
                    name="income_current_otheramount"
                    id="income_current_otheramount"
                        InputProps={{
                        inputComponent: NumberFormatAmount,
                    }}
                    fullWidth
                    value={formValues.income_current_otheramount}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "income_current_otheramount",
                            value: e.target.value
                        })
                    }
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Please Describe Other Source of Income"
                    name="income_current_otherdescribe"
                    fullWidth
                    value={formValues.income_current_otherdescribe}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "income_current_otherdescribe",
                            value: e.target.value
                        })
                    }
                />
            </Grid>


            <Grid item xs={12}>
                <div style={dividerSection}></div>
                <Divider orientation="horizontal" />
                <div style={dividerSection}></div>
                <Typography variant="h6">Past Employment</Typography>
                <Typography>Your past employer and income</Typography>
            </Grid>
            
            <Grid item xs={12}>
                <TextField
                    label="Employer's Individual/Company Name"
                    name="income_past_companyname"
                    fullWidth
                    value={formValues.income_past_companyname}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "income_past_companyname",
                            value: e.target.value
                        })
                    }
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <TextField
                    label="City"
                    name="income_past_companycity"
                    fullWidth
                    value={formValues.income_past_companycity}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "income_past_companycity",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Autocomplete
                    options={commonData.states}
                    getOptionLabel={option => option.name}
                    renderInput={params =>
                        <TextField
                            label="State"
                            name="income_past_companystate"
                            fullWidth
                            {...params}
                        />
                    }
                    value={formValues.income_past_companystate}
                    onChange={(event, value) => {
                        dispatch({
                            type: 'editFormValue',
                            key: "income_past_companystate",
                            value: value
                        })
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    label="ZIP Code"
                    name="income_past_companyzipcode"
                    fullWidth
                    value={formValues.income_past_companyzipcode}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "income_past_companyzipcode",
                            value: e.target.value
                        })
                    }
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                    id="income_past_dateemployedfrom"
                    label="Date Employed From"
                    name="income_past_dateemployedfrom"
                    fullWidth
                    format="MM/dd/yyyy"
                    value={formValues.income_past_dateemployedfrom}
                    onChange={date =>
                        dispatch({
                            type: 'editFormValue',
                            key: "income_past_dateemployedfrom",
                            value: date
                        })
                    }
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                    id="income_past_dateemployedto"
                    label="Date Employed To"
                    name="income_past_dateemployedto"
                    fullWidth
                    format="MM/dd/yyyy"
                    value={formValues.income_past_dateemployedto}
                    onChange={date =>
                        dispatch({
                            type: 'editFormValue',
                            key: "income_past_dateemployedto",
                            value: date
                        })
                    }
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    label="Supervisor's Name"
                    name="income_past_companysupervisorname"
                    fullWidth
                    value={formValues.income_past_companysupervisorname}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "income_past_companysupervisorname",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth={true} required={false}>
                    <InputLabel htmlFor="standard">Supervisor's Phone</InputLabel>
                    <Input
                        label="Supervisor's Phone"
                        inputComponent={TextInputPhone}
                        name="income_past_companysupervisorphonenumber"
                        value={formValues.income_past_companysupervisorphonenumber}
                        onChange={e =>
                            dispatch({
                                type: 'editFormValue',
                                key: "income_past_companysupervisorphonenumber",
                                value: e.target.value
                            })
                        }
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    label="Your Position/Title"
                    name="income_past_companyposition"
                    fullWidth
                    value={formValues.income_past_companyposition}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "income_past_companyposition",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Salary/Wage"
                    name="income_past_companysalary"
                    id="income_past_companysalary"
                        InputProps={{
                        inputComponent: NumberFormatAmount,
                    }}
                    fullWidth
                    value={formValues.income_past_companysalary}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "income_past_companysalary",
                            value: e.target.value
                        })
                    }
                />
            </Grid>

        </MuiPickersUtilsProvider>
    </>
}

export default ApplicantIncomeForm;