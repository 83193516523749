import React from 'react';

import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
    TextField,
    Grid,
    Typography,
    InputLabel,
    IconButton,
    Input,
    InputAdornment,
    FormControl,
    Divider,
} from "@material-ui/core";

import {
    Visibility,
    VisibilityOff
} from "@material-ui/icons";

import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStateValue } from "../../../StateContext";

import { TextInputPhone } from './FormComponents/TextInputPhone';
import { TextInputSSN } from './FormComponents/TextInputSSN';
import commonData from '../../../data/common.json';

import { getEnivornment } from '../../../constants/functions';

const dividerSection = {
    height: "25px"
};

const ApplicantInformationForm = () => {

    const [{ formValues }, dispatch] = useStateValue();
    console.log('getEnivornment', getEnivornment());
    const env = getEnivornment();
    const stage = 'stage';

    const handleClickShowPassword = () => {
        setValues({ ...passwordValues, showPassword: !passwordValues.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [passwordValues, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const StageContent = () => {
        if (env === stage) {
            return <>
                <Typography>
                    <br></br>
                    <strong>You are viewing the stage version of this site.  You may only apply with the information allowed.</strong>
                    <br></br>
                </Typography>
            </>
        }
    };

    /* BACKUP: <MuiPickersUtilsProvider utils={MomentUtils}> */
    return <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            
            <Grid item xs={12}>
                <Typography variant="h6">Applicant Information</Typography>
                <Typography>Additional occupants over 18 years old and Co-Signer must submit a separate application</Typography>
                {StageContent()}
            </Grid>
            <Grid item xs={12} sm={5}>
                <TextField
                    label="First Name"
                    name="information_applicant_firstname"
                    required
                    disabled={(env === stage)}
                    fullWidth
                    value={formValues.information_applicant_firstname}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "information_applicant_firstname",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <TextField
                    label="Middle Initial"
                    name="information_applicant_middlename"
                    required
                    disabled={(env === stage)}
                    fullWidth
                    value={formValues.information_applicant_middlename}
                    inputProps={{ maxLength: 1 }}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "information_applicant_middlename",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} sm={5}>
                <TextField
                    label="Last Name"
                    name="information_applicant_lastname"
                    required
                    disabled={(env === stage)}
                    fullWidth
                    value={formValues.information_applicant_lastname}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "information_applicant_lastname",
                            value: e.target.value
                        })
                    }
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <KeyboardDatePicker
                    id="information_applicant_dateofbirth"
                    label="Date of Birth"
                    name="information_applicant_dateofbirth"
                    required
                    disabled={(env === stage)}
                    fullWidth
                    format="MM/dd/yyyy"
                    value={formValues.information_applicant_dateofbirth}
                    onChange={date =>
                        dispatch({
                            type: 'editFormValue',
                            key: "information_applicant_dateofbirth",
                            value: date
                        })
                    }
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <FormControl 
                    fullWidth={true}
                    required={true}
                    disabled={(env === stage)}
                    >
                    <InputLabel htmlFor="standard">Social Security Number</InputLabel>
                        <Input
                            id="information_applicant_socialsecuritynumber"
                            label="Social Security Number"
                            name="information_applicant_socialsecuritynumber"
                            type={passwordValues.showPassword ? 'text' : 'password'}
                            value={formValues.information_applicant_socialsecuritynumber}
                            inputComponent={TextInputSSN}
                            onChange={e =>
                                dispatch({
                                    type: 'editFormValue',
                                    key: "information_applicant_socialsecuritynumber",
                                    value: e.target.value
                                })
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                {passwordValues.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                                </InputAdornment>
                            }
                        />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Autocomplete
                    options={commonData.genders}
                    disabled={(env === stage)}
                    getOptionLabel={option => option.name}
                    renderInput={params =>
                        <TextField
                            label="Gender"
                            name="information_applicant_gender"
                            required
                            fullWidth
                            {...params}
                        />
                    }
                    value={formValues.information_applicant_gender}
                    onChange={(event, value) => {
                        dispatch({
                            type: 'editFormValue',
                            key: "information_applicant_gender",
                            value: value
                        })
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    label="Email Address"
                    name="information_applicant_email"
                    required
                    fullWidth
                    value={formValues.information_applicant_email}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "information_applicant_email",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth={true} required={true}>
                    <InputLabel htmlFor="standard">Cell Phone Number</InputLabel>
                    <Input
                        label="Cell Phone Number"
                        inputComponent={TextInputPhone}
                        name="information_applicant_cellphonenumber"
                        value={formValues.information_applicant_cellphonenumber}
                        onChange={e =>
                            dispatch({
                                type: 'editFormValue',
                                key: "information_applicant_cellphonenumber",
                                value: e.target.value
                            })
                        }
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    label="Driver's License Number"
                    name="information_applicant_driverslicensenumber"
                    required
                    disabled={(env === stage)}
                    fullWidth
                    value={formValues.information_applicant_driverslicensenumber}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "information_applicant_driverslicensenumber",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Autocomplete
                    options={commonData.states}
                    getOptionLabel={option => option.name}
                    disabled={(env === stage)}
                    renderInput={params =>
                        <TextField
                            label="Driver's License State"
                            name="information_applicant_driverslicensestate"
                            required
                            fullWidth
                            {...params}
                        />
                    }
                    value={formValues.information_applicant_driverslicensestate}
                    onChange={(event, value) => {
                        dispatch({
                            type: 'editFormValue',
                            key: "information_applicant_driverslicensestate",
                            value: value
                        })
                    }}
                />
            </Grid>


            <Grid item xs={12}>                
                <div style={dividerSection}></div>
                <Divider orientation="horizontal" />
                <div style={dividerSection}></div>
                <Typography variant="h6">Requesting Landlord</Typography>
                <Typography>The landlord who is requesting your application</Typography>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Landlord Full Name"
                    name="information_landlord_fullname"
                    required
                    fullWidth
                    value={formValues.information_landlord_fullname}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "information_landlord_fullname",
                            value: e.target.value
                        })
                    }
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    label="Landlord Email Address"
                    name="information_landlord_email"
                    required
                    fullWidth
                    value={formValues.information_landlord_email}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "information_landlord_email",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth={true} required={true}>
                    <InputLabel htmlFor="standard">Landlord Phone Number</InputLabel>
                    <Input
                        label="Cell Phone Number"
                        inputComponent={TextInputPhone}
                        name="information_landlord_phonenumber"
                        value={formValues.information_landlord_phonenumber}
                        onChange={e =>
                            dispatch({
                                type: 'editFormValue',
                                key: "information_landlord_phonenumber",
                                value: e.target.value
                            })
                        }
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Application Referral Code"
                    name="information_landlord_referral"
                    fullWidth
                    value={formValues.information_landlord_referral}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "information_landlord_referral",
                            value: e.target.value
                        })
                    }
                />
            </Grid>

        </MuiPickersUtilsProvider>
    </>
}

export default ApplicantInformationForm;