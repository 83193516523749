import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Stepper,
    Step,
    StepLabel,
    Button,
    Box,
    Grid,
    CircularProgress,
} from '@material-ui/core';
import StepperIcons from "./StepperIcons";

import ApplicantInformationForm from "./Forms/ApplicantInformationForm";
import ApplicantAddressForm from "./Forms/ApplicantAddressForm";
import ApplicantIncomeForm from "./Forms/ApplicantIncomeForm";
import ApplicantAuthorizationForm from "./Forms/ApplicantAuthorizationForm";
import ApplicantReviewForm from "./Forms/ApplicantReviewForm";
import ApplicantComplete from "./Forms/ApplicantComplete";

import { useStateValue } from "../../StateContext";
import StepConnector from './StepConnector'
import {ApplicationFormSave} from '../../ServerCalls/ApplicationFormSave';


// OVERALL STYLE
const style = makeStyles(theme => ({
    button: {
        marginRight: theme.spacing(1),
    },
    mainBox: {
        position: "relative",
        marginTop: "-8px",
        padding: "10px 20px",
        borderBottomRightRadius: "4px",
        borderBottomLeftRadius: "4px",
        background: theme.palette.background.default
    },
    stepper: {
        height: "calc(10vh - 40px)",
        minHeight: "55px"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around"
    },
    buttonWrapper: {
        justifyContent: "flex-end"
    },
}));

const StepContent = ({ step }) => {
    switch (step) {
        case 0:
            return <ApplicantInformationForm />;
        case 1:
            return <ApplicantAddressForm />;
        case 2:
            return <ApplicantIncomeForm />;
        case 3:
            return <ApplicantAuthorizationForm />;
        case 4:
            return <ApplicantReviewForm />;
        case 5:
            return <ApplicantComplete />;
        default:
            return <></>;
    }
}

const Steppers = () => {
    const classes = style();
    const [activeStep, setActiveStep] = useState(0);
    const [loading] = useState(false);
    const [{ formValues }, dispatch] = useStateValue();

    const handleNext = async () => {
        if (activeStep === 3) {
            captureData();
        } else {
            setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
    };
    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);
    //const handleReset = () => setActiveStep(0);

    const captureData = async () => {
        let data = await ApplicationFormSave(formValues);
        let id = data.bodyJSON.id;
        let application_id = data.bodyJSON.application_id;
        await dispatch({
            type: 'editFormValue',
            key: "id",
            value: id
        });
        await dispatch({
            type: 'editFormValue',
            key: "application_id",
            value: application_id
        });
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        
/*
        setLoading(true);

        const clientSecretDataObject = clientSecretDataObjectConverter(formValues);
        const clientSecret = await clientSecretPull(clientSecretDataObject);
        const cardElement = elements.getElement(CardCvcElement);
        const stripeDataObject = stripeDataObjectConverter(formValues, cardElement);
        const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, stripeDataObject);

        if (error) {
            setCardStatus(false);
            setCardMessage(error.message)
        } else if (paymentIntent && paymentIntent.status === "succeeded") {
            setCardStatus(true);
            setCardMessage("");
            dispatch({ type: 'emptyFormValue' });
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setLoading(false);
*/
    }
    const displayPaymentForm = () => {
        if (formValues.payment_complete) {
            return (
                <>
                    <form
                        autoComplete="off"
                        className={classes.form} 
                        onSubmit={e => { e.preventDefault(); handleNext() }}
                        >
                        <Grid container spacing={3}>
                            <ApplicantComplete />
                            <Grid container item justify="flex-end"></Grid>
                        </Grid>
                    </form>
                </>
            );
        } else {
            return (
                <>
                    <form
                        autoComplete="off"
                        className={classes.form} 
                        onSubmit={e => { e.preventDefault(); handleNext() }}
                        >
                        <Grid container spacing={3}>
                            <ApplicantReviewForm />
                            <Grid container item justify="flex-end">
                                <Button 
                                    disabled={activeStep === 0} 
                                    className={classes.button} 
                                    onClick={handleBack}
                                >
                                    Back
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </>
            );
        }
    };
    

    const displayContent = () => {
        if (activeStep === 5) {
            return (
                <>
                    {displayPaymentForm()}
                </>
            );
        } else if (activeStep === 4) {
            return (
                <>
                    {displayPaymentForm()}
                </>
            );
        } else if (activeStep === 3) {
            return (
                <>
                    <form
                        autoComplete="off"
                        className={classes.form} 
                        onSubmit={e => { e.preventDefault(); handleNext() }}
                        >
                        <Grid container spacing={3}>
                            <ApplicantAuthorizationForm />
                            <Grid container item justify="flex-end">
                                <Button 
                                    disabled={activeStep === 0} 
                                    className={classes.button} 
                                    onClick={handleBack}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    type="submit"
                                    disabled={ !formValues.signature_complete }
                                >
                                    {
                                        loading
                                            ?
                                            <CircularProgress size={24} />
                                            :
                                            'Next'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </>
            );
        } else {
            return (
                <>
                    <form
                        autoComplete="off"
                        className={classes.form} 
                        onSubmit={e => { e.preventDefault(); handleNext() }}
                        >
                        <Grid container spacing={3}>
                            <StepContent step={activeStep} />
                            <Grid container item justify="flex-end">
                                <Button 
                                    disabled={activeStep === 0} 
                                    className={classes.button} 
                                    onClick={handleBack}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    type="submit"
                                    disabled={ loading }
                                >
                                    {
                                        loading
                                            ?
                                            <CircularProgress size={24} />
                                            :
                                            'Next'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </form>

                </>
            );
        }
    };

    return (
        <>
            <Stepper alternativeLabel className={classes.stepper} connector={<StepConnector />} activeStep={activeStep}>
                {[1, 2, 3, 4, 5].map(e =>
                    <Step key={e}>
                        <StepLabel StepIconComponent={StepperIcons} />
                    </Step>
                )}
            </Stepper>

            <Box className={classes.mainBox}>
                {displayContent()}
            </Box>
        </>
    );
}

export default Steppers;