import React, { useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Typography,
} from "@material-ui/core";

import {
    Link
} from "react-router-dom";

import LegalPrivacyPolicy from './LegalPrivacyPolicy';
import LegalTermsOfUse from './LegalTermsOfUse';

const NoticeDialog = ({separator}) => {

    const [open, toggleOpen] = useState(false);
    const [dialog, setDialog] = useState("privacy");

    const clickHandler = (event) => {
        //event.preventDefault();
        //setDialog(event.target.name);
        //toggleOpen(true);
    };

    const contentObject = {
        privacy: {
            name: "privacy",
            title: "Privacy Policy",
            url: "../privacy-policy",
            description: () => <><LegalPrivacyPolicy/></>
        },
        tou: {
            name: "tou",
            title: "Terms of Use",
            url: "../terms-of-use",
            description: () => <><LegalTermsOfUse/></>
        },
    }
    //  {Object.keys(contentObject).map((x, i) => <span key={i}>{i > 0 && separator}&nbsp;<Link href={contentObject[x].url} name={contentObject[x].name} onClick={clickHandler}>{contentObject[x].title}</Link></span>)}
    return <Typography variant="caption">
        {Object.keys(contentObject).map((x, i) => <span key={i}>{i > 0 && separator}&nbsp;<Link to={contentObject[x].url}>{contentObject[x].title}</Link></span>)}
        <Dialog
            open={open}
            onClose={() => toggleOpen(false)}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
        >
            <DialogTitle id="dialog-title" color="primary">
                {contentObject[dialog].title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="dialog-description">
                    {contentObject[dialog].description()}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => toggleOpen(false)} color="primary" autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    </Typography>
}

export default NoticeDialog;