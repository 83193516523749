import React from 'react';

import {
  Box,
  Container,
  Paper,
} from '@material-ui/core';
import { makeStyles, } from '@material-ui/core/styles';

import Header from "./Header";
import LegalTermsOfUse from './LegalNoticePopups/LegalTermsOfUse';

const useStyles = makeStyles(theme => ({
  boxWrapper: {
      marginBottom: "55px",
      minHeight: "calc(26vh + 260px)"
  },
  container: {
      position: "relative",
      zIndex: "1100",
      marginTop: "-95px",
      marginBottom: "45px",
  },
  mainBox: {
    position: "relative",
    marginTop: "-8px",
    padding: "10px 20px",
    borderBottomRightRadius: "4px",
    borderBottomLeftRadius: "4px",
    background: theme.palette.background.default
  },
}));

export default function TermsOfUse() {
  const classes = useStyles();
  return (
    <div>
      <Header title="TERMS OF USE" />
      <Box component="main" className={classes.boxWrapper}>
          <Container maxWidth="md" className={classes.container}>
            <Paper elevation={5}>
              <Box className={classes.mainBox}>
                <LegalTermsOfUse />
              </Box>
            </Paper>
          </Container>
        </Box>
    </div>
  );

}