import React from "react";
import {
    AppBar,
    Toolbar,
    Typography
} from "@material-ui/core";
import NoticeDialog from "./LegalNoticePopups/NoticeDialog";

const Footer = () => {

    /*
        <Grid container justify="center" style={{minHeight: "212px"}}>
            <Grid container item xs={11} sm={6} justify="space-between">
                <Grid item xs={11} sm={12}>
                    <Typography variant="h6">Authorization – Tenant Disclosure and Release</Typography>
                    <Typography paragraph>
                        In connection with my tenant application with DHA, LLC, I understand that consumer reports which may contain public record information may be requested to evaluate my rental application. These reports may include the following types of information: names and dates of previous employers, public records, credit data, bankruptcy proceedings, civil judgements, eviction and criminal records, etc., from federal, state and other agencies which maintain such records. I hereby authorize procurement of consumer report(s). If approved as a tenant, this authorization shall remain on file and shall serve as ongoing authorization for DHA, LLC to procure consumer reports at any time during my contract period. 
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    */
   
    return <>
        <AppBar position="static" elevation={0} component="footer" color="default">
            <Toolbar style={{ justifyContent: "center" }}>
                <Typography variant="caption">©2021 Qualofied</Typography> <NoticeDialog  separator="&nbsp;᛫" />
            </Toolbar>
        </AppBar>
    </>
}

export default Footer;