import React from "react";
import {
    Typography,
} from "@material-ui/core";

const LegalTermsOfUse = () => {

    return <>
                This website featured to you is subject to the following Terms and our Privacy Notice. If you visit our websites you accept these conditions.<br />
                Please read them carefully.<br /><br />
                <Typography variant="h6">1. Scope and Addressees</Typography>
                This website is operated and administered by DHA, LLC. (see "Legal Notice"), and therefore complies with the applicable laws.<br /><br />
                <Typography variant="h6">2. Data Privacy Protection</Typography>
                Personally identifiable data of the user of this website will be collected, used and processed in compliance with applicable laws on data privacy protection and our "Privacy Notice".<br /><br />
                <Typography variant="h6">3. Intellectual Property Rights</Typography>
                All materials available on this site are protected by copyright laws and international copyright treaty provisions.<br /><br />
                <Typography variant="h6">4. Hyperlinks</Typography>
                The Site may contain hyperlinks to the web pages of third parties.<br />
                We shall have no liability for the contents of such web pages and does not make representations about or endorse such web pages or their contents as its own, as we do not control the information on such web pages and is not responsible for the contents and information given thereon. The use of such web pages shall be at the sole risk of the user.<br /><br />
                <Typography variant="h6">5. Applicable Law, Place of Jurisdiction</Typography>
                These Terms of Use including its disclaimers, terms and conditions shall be governed by—and all disputes relating to or in connection with these Terms of Use or their subject matter shall be resolved in accordance with—the European laws, without giving effect to any principles of conflicts of laws.<br />
                We reserve the right to make changes to this site and these disclaimers, terms and conditions at any time.<br />
                User hereby irrevocably and unconditionally consents to submit to the exclusive jurisdiction of the European court for any litigation arising out of or relating to use of this site (and agrees not to commence any litigation relating thereto except in such courts) waives any objection to the laying of venue of any such litigation.<br /><br />
                <Typography variant="h6">6. Severability</Typography>
                The provisions of these Terms of Use are intended to be severable. If for any reason any provision of these Terms of Use shall be held invalid or unenforceable in whole or in part in any jurisdiction, such provision shall, as to such jurisdiction, be ineffective to the extent of such invalidity or unenforceability without in any manner affecting the validity or enforceability thereof in any other jurisdiction or the remaining provisions hereof in any jurisdiction.<br />
        </>
}

export default LegalTermsOfUse;