export const baseAPI = {
    active: 'https://api.qualofied.com',
    local: 'http://localhost:3001',
    dev: 'https://hzexx08xpc.execute-api.us-west-1.amazonaws.com/dev',
    prod: 'https://api.qualofied.com'
}

export const baseSite = {
    local: 'http://localhost:3000',
    stage: 'https://stage.qualofied.com',
    prod: 'https://qualofied.com'
}

export const getEnivornment = () => {
    var origin = window.location.origin;
    var env = 'local';
    switch (origin) {
        case 'http://localhost:3000':
            env = 'local';
            break;
        case 'https://stage.qualofied.com':
            env = 'stage';
            break;
        case 'http://stage.qualofied.com':
            env = 'stage';
            break;
        case 'https://qualofied.com':
            env = 'prod';
            break;
        case 'http://qualofied.com':
            env = 'prod';
            break;
        default:
            env = 'local';
    }
    return env;
}

export const setCookie = (name, value, days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export const getCookie = name => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export const eraseCookie = name => {
    document.cookie = name + '=; Max-Age=-99999999;';
}