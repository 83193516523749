import axios from 'axios';
import { baseAPI } from '../constants/functions';

var headers = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET"
};

const TazworksPostOrder = async (data) => {
    console.log(data);
    //data = { "application_id": "c290f3332492d8daee6496c2877cd5dd20211024", "products": [] };
    const res = await axios({
        url: baseAPI.active + '/tazworks/order/',
        method: 'POST',
        port: 443,
        data: data,
        timeout: 8000,
        headers: headers,
    })
    return res;
}

const TazworksPostResponse = async (data) => {
    //data = { "order_guid": "ed7d8a13-87b4-4938-9ef4-08b46399657c", application_id: 'c290f3332492d8daee6496c2877cd5dd20201028' };
    const res = await axios({
        url: baseAPI.active + '/tazworks/response/',
        method: 'POST',
        port: 443,
        timeout: 8000,
        data: data,
        headers: headers,
    })
    return res;
}


export { TazworksPostOrder, TazworksPostResponse };