import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  useParams
} from "react-router-dom";

import {
  Box,
  Container,
  Paper,
  Grid,
} from '@material-ui/core';

import Header from "./Header";

import ReportViewer from "./Reports/ReportViewer";
import ReportRequest from "./Reports/ReportRequest";

import { makeStyles, } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  boxWrapper: {
      marginBottom: "55px",
      minHeight: "calc(26vh + 260px)"
  },
  container: {
      position: "relative",
      zIndex: "1100",
      marginTop: "-95px",
      marginBottom: "45px",
  },
  button: {
      marginRight: theme.spacing(1),
  },
  mainBox: {
      position: "relative",
      marginTop: "-8px",
      padding: "10px 20px",
      borderBottomRightRadius: "4px",
      borderBottomLeftRadius: "4px",
      background: theme.palette.background.default
  },
  stepper: {
      height: "calc(10vh - 40px)",
      minHeight: "55px"
  },
  form: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around"
  },
  buttonWrapper: {
      justifyContent: "flex-end"
  },
}));


export default function Report() {
  let match = useRouteMatch();
  const classes = useStyles();

  return (
      <div>
        <Header title="REPORTS" logoLink="logo.svg" />
        <div style={{flexGrow: 1, padding: '30px'}}>
          <Box component="main" className={classes.boxWrapper}>
              <Container maxWidth="md" className={classes.container}>
                <Paper elevation={5}>
                  <Box className={classes.mainBox}>
                  <Switch>
                    <Route path={`${match.path}/:jwt`}>
                        <ReportView />
                    </Route>
                    <Route path={match.path}>
                      <Grid container item justify="flex">
                        <h3>Request Report</h3>
                        <ReportRequest />
                        </Grid>
                    </Route>
                  </Switch>
                </Box>
              </Paper>
            </Container>
          </Box>
        </div>
      </div>
  );

}


function ReportView() {
  let { jwt } = useParams();
  console.log(jwt);
  return (
    <ReportViewer jwt={jwt} />
  );
}