import axios from 'axios';
import { baseAPI } from '../constants/functions';
const { encrypt } = require('../constants/encryption');

const FormatDate = (date) => {
    let d = new Date(date);
    let ye = new Intl.DateTimeFormat('en', {
        year: 'numeric'
    }).format(d);
    let mo = new Intl.DateTimeFormat('en', {
        month: '2-digit'
    }).format(d);
    let da = new Intl.DateTimeFormat('en', {
        day: '2-digit'
    }).format(d);
    let formattedDate = `${ye}-${mo}-${da}`;
    if (date) {
        return formattedDate;
    } else {
        return '';
    }
}

const FormatData = (type, data) => {
    switch (type) {
        case 'date':
            data = FormatDate(data);
            break;
        case 'total':
            data = parseInt(data[0]) + parseInt(data[1]);
            break;
        default:
            break;
    }
    return data;
}

const FormatPhone = (phone) => {
    let phoneArray = phone.split('-');
    var phoneFormatted = '(' + phoneArray[1] + ') ' + phoneArray[2] + '-' + phoneArray[3];
    return phoneFormatted;
}

const FormatCurrency = (currency) => {
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    if (currency > 0) {
        return formatter.format(currency);
    } else {
        return '';
    }
};

const FormatCode = (data) => {
    if (data.code) {
        return data.code;
    } else {
        return '';
    }
};

const ApplicationFormSave = async (formValues) => {
    
    var data = {
        "formData": {
            "id": encrypt(formValues.information_applicant_socialsecuritynumber),
            "application_id": formValues.application_id,
            "information_applicant_firstname": formValues.information_applicant_firstname,
            "information_applicant_middlename": formValues.information_applicant_middlename,
            "information_applicant_lastname": formValues.information_applicant_lastname,
            "information_applicant_dateofbirth": FormatDate(formValues.information_applicant_dateofbirth),
            "information_applicant_socialsecuritynumber": formValues.information_applicant_socialsecuritynumber,
            "information_applicant_gender": FormatCode(formValues.information_applicant_gender),
            "information_applicant_email": formValues.information_applicant_email,
            "information_applicant_cellphonenumber": formValues.information_applicant_cellphonenumber,
            "information_applicant_driverslicensenumber": formValues.information_applicant_driverslicensenumber,
            "information_applicant_driverslicensestate": FormatCode(formValues.information_applicant_driverslicensestate),
            "information_landlord_fullname": formValues.information_landlord_fullname,
            "information_landlord_email": formValues.information_landlord_email,
            "information_landlord_phonenumber": formValues.information_landlord_phonenumber,
            "information_landlord_referral": formValues.information_landlord_referral,
            "address_present_address1": formValues.address_present_address1,
            "address_present_address2": formValues.address_present_address2,
            "address_present_city": formValues.address_present_city,
            "address_present_state": FormatCode(formValues.address_present_state),
            "address_present_zipcode": formValues.address_present_zipcode,
            "address_present_reasonforleaving": formValues.address_present_reasonforleaving,
            "address_present_dateoccupiedfrom": FormatDate(formValues.address_present_dateoccupiedfrom),
            "address_present_dateoccupiedto": FormatDate(formValues.address_present_dateoccupiedto),
            "address_present_noticegiven": FormatCode(formValues.address_present_noticegiven),
            "address_present_rentamount": FormatCurrency(formValues.address_present_rentamount),
            "address_present_depositamount": FormatCurrency(formValues.address_present_depositamount),
            "address_present_landlordfullname": formValues.address_present_landlordfullname,
            "address_present_landlordemail": formValues.address_present_landlordemail,
            "address_present_landlordphonenumber": formValues.address_present_landlordphonenumber,
            "address_previous_address1": formValues.address_previous_address1,
            "address_previous_address2": formValues.address_previous_address2,
            "address_previous_city": formValues.address_previous_city,
            "address_previous_state": FormatCode(formValues.address_previous_state),
            "address_previous_zipcode": formValues.address_previous_zipcode,
            "address_previous_reasonforleaving": formValues.address_previous_reasonforleaving,
            "address_previous_dateoccupiedfrom": FormatDate(formValues.address_previous_dateoccupiedfrom),
            "address_previous_dateoccupiedto": FormatDate(formValues.address_previous_dateoccupiedto),
            "address_previous_rentamount": FormatCurrency(formValues.address_previous_rentamount),
            "address_previous_landlordfullname": formValues.address_previous_landlordfullname,
            "address_previous_landlordemail": formValues.address_previous_landlordemail,
            "address_previous_landlordphonenumber": formValues.address_previous_landlordphonenumber,
            "income_current_companyname": formValues.income_current_companyname,
            "income_current_companycity": formValues.income_current_companycity,
            "income_current_companystate": FormatCode(formValues.income_current_companystate),
            "income_current_companyzipcode": formValues.income_current_companyzipcode,
            "income_current_dateemployedfrom": FormatDate(formValues.income_current_dateemployedfrom),
            "income_current_dateemployedto": FormatDate(formValues.income_current_dateemployedto),
            "income_current_companysupervisorname": formValues.income_current_companysupervisorname,
            "income_current_companysupervisorphonenumber": formValues.income_current_companysupervisorphonenumber,
            "income_current_companyposition": formValues.income_current_companyposition,
            "income_current_companysalary": FormatCurrency(formValues.income_current_companysalary),
            "income_current_otherincome": formValues.income_current_otherincome,
            "income_current_otheramount": FormatCurrency(formValues.income_current_otheramount),
            "income_current_otherdescribe": formValues.income_current_otherdescribe,
            "income_past_companyname": formValues.income_past_companyname,
            "income_past_companycity": formValues.income_past_companycity,
            "income_past_companystate": FormatCode(formValues.income_past_companystate),
            "income_past_companyzipcode": formValues.income_past_companyzipcode,
            "income_past_dateemployedfrom": FormatDate(formValues.income_past_dateemployedfrom),
            "income_past_dateemployedto": FormatDate(formValues.income_past_dateemployedto),
            "income_past_companysupervisorname": formValues.income_past_companysupervisorname,
            "income_past_companysupervisorphonenumber": formValues.income_past_companysupervisorphonenumber,
            "income_past_companyposition": formValues.income_past_companyposition,
            "income_past_companysalary": FormatCurrency(formValues.income_past_companysalary),
            "authorization_initials": formValues.authorization_initials,
            "authorization_fullname": formValues.authorization_fullname,
            "authorization_signature": formValues.authorization_signature,
            "authorization_signeddate": FormatDate(formValues.authorization_signeddate),
            "services_products": formValues.services_products,
            "payment_complete": formValues.payment_complete,
            "signature_complete": formValues.signature_complete,
            "order_transaction_id": formValues.order_transaction_id,
            "order_transaction_amount": formValues.order_transaction_amount,
            "order_transaction_customer": formValues.order_transaction_customer,
        },
        "applicantData": {
          "applicantGuid": null,
          "firstName": formValues.information_applicant_firstname,
          "middleName": formValues.information_applicant_middlename,
          "noMiddleName": false,
          "lastName": formValues.information_applicant_lastname,
          "generation": null,
          "gender": formValues.information_applicant_gender.code,
          "ssn": formValues.information_applicant_socialsecuritynumber,
          "race": null,
          "dateOfBirth": FormatData('date', formValues.information_applicant_dateofbirth),
          "email": formValues.information_applicant_email,
          "phoneNumber": FormatPhone(formValues.information_applicant_cellphonenumber),
          "textingEnabled": true,
          "driverLicense": formValues.information_applicant_driverslicensenumber,
          "driverLicenseState": formValues.information_applicant_driverslicensestate.code,
          "proposedPosition": formValues.income_current_companyposition,
          "proposedSalary": "salary",
          "monthlyIncome": FormatData('total', [formValues.income_current_companysalary, formValues.income_current_otheramount]),
          "monthlyDebt": null,
          "monthlyRent": parseInt(formValues.address_present_rentamount),
          "desiredUnit": "unit",
          "referredBy": formValues.information_landlord_fullname,
          "jobCode": "JOBCODE",
          "jobLocation": formValues.income_current_companyname,
          "stateOfEmployment": formValues.income_current_companystate.code,
          "cityOfEmployment": formValues.income_current_companycity,
          "countyOfEmployment": formValues.income_current_companyzipcode
        },
        "applicantAddressData": {
          "addressGuid": null,
          "addressType": "DOMESTIC",
          "startDate": FormatData('date', formValues.address_present_dateoccupiedfrom),
          "endDate": FormatData('date', formValues.address_present_dateoccupiedto),
          "streetOne": formValues.address_present_address1,
          "streetTwo": formValues.address_present_address2,
          "city": formValues.address_present_city,
          "stateOrProvince": formValues.address_present_state.code,
          "postalCode": formValues.address_present_zipcode,
          "country": null,
          "comments": formValues.address_present_reasonforleaving,
          "verifyResidence": true,
          "landlordName": formValues.address_present_landlordfullname,
          "landlordPhone": FormatPhone(formValues.address_present_landlordphonenumber),
          "landlordEmail": formValues.address_present_landlordemail,
          "landlordFax": null,
          "monthlyRent": parseInt(formValues.address_present_rentamount),
          "namesOfResidents": null,
          "residenceType": "RENT"
        }
      };
    
    data.formData = encrypt( JSON.stringify(data.formData) );
    data.applicantData = encrypt( JSON.stringify(data.applicantData) );
    data.applicantAddressData = encrypt( JSON.stringify(data.applicantAddressData) );

    var headers = {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST"
    };
    
    try {
        let res = await axios({
             url: baseAPI.active + '/application/submit/',
             method: 'POST',
             data: data,
             port: 443,
             timeout: 8000,
             headers: headers,
         })
         if(res.status === 200){
             //success
             //console.log(res.data.bodyJSON.formData.id);
             console.log(res.data);
         }
         return res.data
     }
     catch (err) {
         console.error(err);
     }
}

export { ApplicationFormSave };