import React from 'react';
import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import {
    TextField,
    Grid,
    Typography,
    Divider,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { useStateValue } from "../../../StateContext";
import BraintreePaymentForm from './FormComponents/BraintreePaymentForm';
import PaymentSummary from './FormComponents/PaymentSummary';

const dividerSection = {
    height: "25px"
};

const DarkerDisabledTextField = withStyles({
    root: {
      marginRight: 8,
      "& .MuiInputBase-root.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.9)" // (default alpha is 0.38)
      }
    }
})(TextField);

const ApplicantReviewForm = () => {

    const [{ formValues }] = useStateValue();

    return <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            
            <Grid item xs={12}>
                <Typography variant="h6">Review and Pay</Typography>
                <Typography>A copy of the credit report will be sent to the following names and emails listed below.</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
                <DarkerDisabledTextField
                    label="Your Full Name"
                    name="information_applicant_firstname"
                    required
                    fullWidth
                    disabled={true}
                    value={formValues.information_applicant_firstname + ' ' + formValues.information_applicant_middlename + '. ' + formValues.information_applicant_lastname}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DarkerDisabledTextField
                    label="Your Email Address"
                    name="information_applicant_email"
                    required
                    fullWidth
                    value={formValues.information_applicant_email}
                    disabled={true}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <DarkerDisabledTextField
                    label="Requesting Landlord Full Name"
                    name="information_landlord_fullname"
                    required
                    fullWidth
                    value={formValues.information_landlord_fullname}
                    disabled={true}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DarkerDisabledTextField
                    label="Requesting Landlord Email Address"
                    name="information_landlord_email"
                    required
                    fullWidth
                    value={formValues.information_landlord_email}
                    disabled={true}
                />
            </Grid>


            <Grid item xs={12}>                
                <div style={dividerSection}></div>
                <Divider orientation="horizontal" />
                <div style={dividerSection}></div>
                <Typography variant="h6">Payment Summary</Typography>
            </Grid>

            <Grid item xs={12}>
                <PaymentSummary />
            </Grid>


            <Grid item xs={12}>                
                <div style={dividerSection}></div>
                <Divider orientation="horizontal" />
                <div style={dividerSection}></div>
                <Typography variant="h6">Payment Authorization</Typography>
            </Grid>

            <Grid item xs={12}>
                <BraintreePaymentForm/>
            </Grid>

        </MuiPickersUtilsProvider>
    </>
}

export default ApplicantReviewForm;