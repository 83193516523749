//https://github.com/react-pdf-viewer/react-pdf-viewer

import React from "react";
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
//import { OpenFile } from '@react-pdf-viewer/core';

import {
    Link
} from "react-router-dom";

import {
    Grid,
    Typography,
    Button,
    Box,
} from "@material-ui/core";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import {ReportsCallInit, ReportsCallGet} from '../../ServerCalls/ReportsCalls';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';

class ReportViewerImpl extends React.Component {
    instance; 
    state = {
        url: null,
        reports: null,
        error: null,
        active: null,
        jwt: null,
        uuid: null,
        reportData: {}
    };

    async base64toBlob(data) {
        const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }
        return new Blob([out], { type: 'application/pdf' });
    }

    async componentDidMount() {
        const uuid = this.props.children[1];
        const data = {
            uuid: uuid
        }
        const response = await ReportsCallInit(data);
        //console.log(response.error)
        if (response.error) {
            this.setState({
                error: response.error
            });
        } else {    
            this.setState({
                reports: response.responseData.reportsData,
                uuid: uuid,
                jwt: response.responseData.jwtData
            });
        }
    };

    async getReport(id) {
        const data = {
            jwt: this.state.jwt,
            report: this.state.reports[id]
        };

        console.log(data);

        var reportData = this.state.reportData;

        if (reportData[data.report.id]) {
            this.setState({
                url: reportData[data.report.id],
            });
        } else {
            const response = await ReportsCallGet(data);

            if (response.error) {
                this.setState({
                    error: response.error
                });
            } else {
                console.log(response);
                const base64 = response.responseData.base64data;
                const blob = await this.base64toBlob(base64);
                const url = URL.createObjectURL(blob);
        
                reportData[data.report.id] = url;
                this.setState({
                    url: url,
                    reportData: reportData
                });
            }
        }
    }
  
    formatDate = (date) => {
        let d = new Date(date);
        let ye = new Intl.DateTimeFormat('en', {
            year: 'numeric'
        }).format(d);
        let mo = new Intl.DateTimeFormat('en', {
            month: 'long'
        }).format(d);
        let da = new Intl.DateTimeFormat('en', {
            day: 'numeric'
        }).format(d);
        let formattedDate = `${mo} ${da}, ${ye}`;
        return formattedDate;
    };

    handleButtonReport = (id) => {
        console.log('handleButtonReport', id);
        this.getReport(id);
    };

    displayReports = () => {
        if (this.state.reports) {

            var rows = [];
            for (var i=0; i<this.state.reports.length; i++) {
                var row = {
                    id: i,
                    name: this.state.reports[i].name,
                    type: this.state.reports[i].type,
                    date: this.formatDate(this.state.reports[i].date)
                }
                rows.push(row);
            }

            return (
                <div>
                    <Grid item xs={12}>
                        <Typography variant="h6">Your Reports</Typography>
                        <Typography>Select a report to view, download, or print.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                            <TableCell>Report Name</TableCell>
                            <TableCell align="right">Date Created</TableCell>
                            <TableCell align="right">View File</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">
                                    {row.date}
                                </TableCell>
                                <TableCell align="right">
                                    <Button 
                                        id={row.id}
                                        key={row.id}
                                        onClick={this.handleButtonReport.bind(this, row.id)}
                                    >
                                        View
                                    </Button>    
                                </TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    </Grid>
                </div>
            );
        }
    };

    displayPDF = () => {
        if (!this.state.url) {
            return (
                <div>
                    
                </div>
            );
        } else {
            // <DownloadButton />
            // const { DownloadButton } = this.props.getFilePluginInstance;
            return (
                <Grid item xs={12} style={{overflow: 'scroll', maxHeight: '554px'}}>
                    <Viewer
                        fileUrl={this.state.url}
                        initialPage={0}
                        theme='auto'
                        // defaultScale={1.2}
                        plugins={[
                            this.props.defaultLayoutPluginInstance,
                            //this.props.getFilePluginInstance
                        ]}
                    />
                </Grid>
            );
        }
    };
  
    render() {
        if (this.state.error) {
            return (
                <div>
                    <h1>Your report has expired or is not found</h1>
                    Please request a new report by completing the <Link to="/reports">report request form here</Link>.
                    <br></br>
                    Or by completing a new <Link to="/application">application form here</Link>.
                </div>
            );
        } else if (!this.state.reports) {
            return (
                <div>
                    <h1>Loading...</h1>
                </div>
            );
        } else {
            return (
                <Box>
                    {this.displayReports()}
                    {this.displayPDF()}
                    <Worker 
                        workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.js">                        
                    </Worker>
                </Box>
            );
        }
    }
}

//const renderToolbar = (Toolbar: ((props: ToolbarProps) => ReactElement)) => (
const renderToolbar = (Toolbar) => (
    <>
        <Toolbar />
        <div
            style={{
                borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                marginTop: '4px',
                padding: '4px',
            }}
        >
            Print and save as a PDF if your download has issues.
        </div>
    </>
);

const ReportViewer = (_props) => {
    //const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar,
    });
    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator: (file) => {
            console.log(file);
            const fileName = file.name.substring(file.name.lastIndexOf('/') + 1);
            return `a-copy-of-${fileName}`;
        },
    });
    return (
        <ReportViewerImpl defaultLayoutPluginInstance={defaultLayoutPluginInstance} getFilePluginInstance={getFilePluginInstance}> jwt={_props.jwt} </ReportViewerImpl>
    );
};

export default ReportViewer;
//workerUrl="https://unpkg.com/pdfjs-dist@2.7.570/build/pdf.worker.js">