import React from 'react';
import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
    TextField,
    Grid,
    Typography,
    InputLabel,
    Divider,
    Input,
    FormControl
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStateValue } from "../../../StateContext";
import { TextInputPhone } from './FormComponents/TextInputPhone';
import NumberFormatAmount from './FormComponents/NumberFormatAmount';
import commonData from '../../../data/common.json';

const dividerSection = {
    height: "25px"
};

const ApplicantAddressForm = () => {

    const [{ formValues }, dispatch] = useStateValue();

    /* BACKUP: <MuiPickersUtilsProvider utils={MomentUtils}> */
    return <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            
            <Grid item xs={12}>
                <Typography variant="h6">Applicant Present Address</Typography>
                <Typography>Your present address</Typography>
            </Grid>
            
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Street Address 1"
                    name="address_present_address1"
                    required
                    fullWidth
                    value={formValues.address_present_address1}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "address_present_address1",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Street Address 2 (optional)"
                    name="address_present_address2"
                    fullWidth
                    value={formValues.address_present_address2}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "address_present_address2",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    label="City"
                    name="address_present_city"
                    required
                    fullWidth
                    value={formValues.address_present_city}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "address_present_city",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Autocomplete
                    options={commonData.states}
                    getOptionLabel={option => option.name}
                    renderInput={params =>
                        <TextField
                            label="State"
                            name="address_present_state"
                            required
                            fullWidth
                            {...params}
                        />
                    }
                    value={formValues.address_present_state}
                    onChange={(event, value) => {
                        dispatch({
                            type: 'editFormValue',
                            key: "address_present_state",
                            value: value
                        })
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    label="ZIP Code"
                    name="address_present_zipcode"
                    required
                    fullWidth
                    value={formValues.address_present_zipcode}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "address_present_zipcode",
                            value: e.target.value
                        })
                    }
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Reason for Leaving"
                    name="address_present_reasonforleaving"
                    required
                    fullWidth
                    value={formValues.address_present_reasonforleaving}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "address_present_reasonforleaving",
                            value: e.target.value
                        })
                    }
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <KeyboardDatePicker
                    id="address_present_dateoccupiedfrom"
                    label="Date Occupied From"
                    name="address_present_dateoccupiedfrom"
                    required
                    fullWidth
                    format="MM/dd/yyyy"
                    value={formValues.address_present_dateoccupiedfrom}
                    onChange={date =>
                        dispatch({
                            type: 'editFormValue',
                            key: "address_present_dateoccupiedfrom",
                            value: date
                        })
                    }
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <KeyboardDatePicker
                    id="address_present_dateoccupiedto"
                    label="Date Occupied To"
                    name="address_present_dateoccupiedto"
                    required
                    fullWidth
                    format="MM/dd/yyyy"
                    value={formValues.address_present_dateoccupiedto}
                    onChange={date =>
                        dispatch({
                            type: 'editFormValue',
                            key: "address_present_dateoccupiedto",
                            value: date
                        })
                    }
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Autocomplete
                    options={commonData.binary}
                    getOptionLabel={option => option.name}
                    renderInput={params =>
                        <TextField
                            label="Has Notice been Given?"
                            name="address_present_noticegiven"
                            required
                            fullWidth
                            {...params}
                        />
                    }
                    value={formValues.address_present_noticegiven}
                    onChange={(event, value) => {
                        dispatch({
                            type: 'editFormValue',
                            key: "address_present_noticegiven",
                            value: value
                        })
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    label="Monthly Rent Amount"
                    name="address_present_rentamount"
                    id="address_present_rentamount"
                        InputProps={{
                        inputComponent: NumberFormatAmount,
                    }}
                    required
                    fullWidth
                    value={formValues.address_present_rentamount}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "address_present_rentamount",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Security Deposit Amount"
                    name="address_present_depositamount"
                    id="address_present_depositamount"
                        InputProps={{
                        inputComponent: NumberFormatAmount,
                    }}
                    required
                    fullWidth
                    value={formValues.address_present_depositamount}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "address_present_depositamount",
                            value: e.target.value
                        })
                    }
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Landlord/Property Manager Name"
                    name="address_present_landlordfullname"
                    required
                    fullWidth
                    value={formValues.address_present_landlordfullname}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "address_present_landlordfullname",
                            value: e.target.value
                        })
                    }
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    label="Landlord/Manager’s Email Address"
                    name="address_present_landlordemail"
                    required
                    fullWidth
                    value={formValues.address_present_landlordemail}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "address_present_landlordemail",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth={true} required={true}>
                    <InputLabel htmlFor="standard">Landlord/Manager’s Phone</InputLabel>
                    <Input
                        label="Landlord/Manager’s Phone"
                        inputComponent={TextInputPhone}
                        name="address_present_landlordphonenumber"
                        value={formValues.address_present_landlordphonenumber}
                        onChange={e =>
                            dispatch({
                                type: 'editFormValue',
                                key: "address_present_landlordphonenumber",
                                value: e.target.value
                            })
                        }
                    />
                </FormControl>
            </Grid>


            <Grid item xs={12}>
                <div style={dividerSection}></div>
                <Divider orientation="horizontal" />
                <div style={dividerSection}></div>
                <Typography variant="h6">Applicant Previous Address</Typography>
                <Typography>Your previous address</Typography>
            </Grid>
            
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Street Address 1"
                    name="address_previous_address1"
                    fullWidth
                    value={formValues.address_previous_address1}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "address_previous_address1",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Street Address 2 (optional)"
                    name="address_previous_address2"
                    fullWidth
                    value={formValues.address_previous_address2}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "address_previous_address2",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    label="City"
                    name="address_previous_city"
                    fullWidth
                    value={formValues.address_previous_city}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "address_previous_city",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Autocomplete
                    options={commonData.states}
                    getOptionLabel={option => option.name}
                    renderInput={params =>
                        <TextField
                            label="State"
                            name="address_previous_state"
                            fullWidth
                            {...params}
                        />
                    }
                    value={formValues.address_previous_state}
                    onChange={(event, value) => {
                        dispatch({
                            type: 'editFormValue',
                            key: "address_previous_state",
                            value: value
                        })
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    label="ZIP Code"
                    name="address_previous_zipcode"
                    fullWidth
                    value={formValues.address_previous_zipcode}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "address_previous_zipcode",
                            value: e.target.value
                        })
                    }
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Reason for Leaving"
                    name="address_previous_reasonforleaving"
                    fullWidth
                    value={formValues.address_previous_reasonforleaving}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "address_previous_reasonforleaving",
                            value: e.target.value
                        })
                    }
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <KeyboardDatePicker
                    id="address_previous_dateoccupiedfrom"
                    label="Date Occupied From"
                    name="address_previous_dateoccupiedfrom"
                    fullWidth
                    format="MM/dd/yyyy"
                    value={formValues.address_previous_dateoccupiedfrom}
                    onChange={date =>
                        dispatch({
                            type: 'editFormValue',
                            key: "address_previous_dateoccupiedfrom",
                            value: date
                        })
                    }
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <KeyboardDatePicker
                    id="address_previous_dateoccupiedto"
                    label="Date Occupied To"
                    name="address_previous_dateoccupiedto"
                    fullWidth
                    format="MM/dd/yyyy"
                    value={formValues.address_previous_dateoccupiedto}
                    onChange={date =>
                        dispatch({
                            type: 'editFormValue',
                            key: "address_previous_dateoccupiedto",
                            value: date
                        })
                    }
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    label="Monthly Rent Amount"
                    name="address_previous_rentamount"
                    id="address_previous_rentamount"
                        InputProps={{
                        inputComponent: NumberFormatAmount,
                    }}
                    fullWidth
                    value={formValues.address_previous_rentamount}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "address_previous_rentamount",
                            value: e.target.value
                        })
                    }
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Landlord/Property Manager Name"
                    name="address_previous_landlordfullname"
                    fullWidth
                    value={formValues.address_previous_landlordfullname}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "address_previous_landlordfullname",
                            value: e.target.value
                        })
                    }
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    label="Landlord/Manager’s Email Address"
                    name="address_previous_landlordemail"
                    fullWidth
                    value={formValues.address_previous_landlordemail}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "address_previous_landlordemail",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth={true} required={false}>
                    <InputLabel htmlFor="standard">Landlord/Manager’s Phone</InputLabel>
                    <Input
                        label="Landlord/Manager’s Phone"
                        inputComponent={TextInputPhone}
                        name="address_previous_landlordphonenumber"
                        value={formValues.address_previous_landlordphonenumber}
                        onChange={e =>
                            dispatch({
                                type: 'editFormValue',
                                key: "address_previous_landlordphonenumber",
                                value: e.target.value
                            })
                        }
                    />
                </FormControl>
            </Grid>

        </MuiPickersUtilsProvider>
    </>
}

export default ApplicantAddressForm;