const crypto = require('crypto');

const algorithm = 'aes-256-cbc';
const secretKey = 'zSBQ4MZLt56vmcgALHtRNWVEMfT9zr2P';
const iv = Buffer.from('8BL3yEAKf6yK5j2M');

export const encrypt = (text) => {
    const cipher = crypto.createCipheriv(algorithm, secretKey, iv);
    const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);
    //iv: iv.toString('hex'),
    return encrypted.toString('hex');
};