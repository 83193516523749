import axios from 'axios';
import { baseAPI } from '../constants/functions';

var headers = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET"
};

const BraintreePostToken = async (data) => {
    const res = await axios({
        url: baseAPI.active + '/payment/token/',
        method: 'POST',
        port: 443,
        data: data,
        timeout: 8000,
        headers: headers,
    })
    return res;
}

const BraintreePostPayment = async (data) => {
    const res = await axios({
        url: baseAPI.active + '/payment/process/',
        method: 'POST',
        port: 443,
        timeout: 8000,
        data: data,
        headers: headers,
    })
    return res;
}


export { BraintreePostToken, BraintreePostPayment };