import React from 'react';
import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
    TextField,
    Grid,
    Typography,
} from "@material-ui/core";

import { useStateValue } from "../../../StateContext";
import SignatureForm from './FormComponents/SignatureForm';

const ApplicantAuthorizationForm = () => {
    const [{ formValues }, dispatch] = useStateValue();

    const handleSignature = (data) => {

        if (data !== null) {
            dispatch({
                type: 'editFormValue',
                key: "authorization_signature",
                value: data
            });
            dispatch({
                type: 'editFormValue',
                key: "signature_complete",
                value: true
            });
        } else {
            dispatch({
                type: 'editFormValue',
                key: "signature_complete",
                value: false
            });
        }
        
    };

    return <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            
            <Grid item xs={12}>
                <Typography variant="h6">Applicant Authorization</Typography>
                <Typography>By initialling and signing below, I authorize the following:</Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h6">Authorization – Tenant Disclosure and Release</Typography>
                <Typography>In connection with my tenant application with DHA, LLC, I understand that consumer reports which may contain public record information may be requested to evaluate my rental application. These reports may include the following types of information: names and dates of previous employers, public records, credit data, bankruptcy proceedings, civil judgements, eviction and criminal records, etc., from federal, state and other agencies which maintain such records. I hereby authorize procurement of consumer report(s). If approved as a tenant, this authorization shall remain on file and shall serve as ongoing authorization for DHA, LLC to procure consumer reports at any time during my contract period.</Typography>
            </Grid>

            <Grid item xs={2}>
                <TextField
                    label="Initials"
                    name="authorization_initials"
                    fullWidth
                    required
                    inputProps={{ maxLength: 3 }}
                    value={formValues.authorization_initials}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "authorization_initials",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={10}>
                <Typography>I authorize DHA, LLC to run my credit for tenant screening.  This will result in a soft hit and not affect my FICO (Fair Isaac Corporation) or Vantage credit score.</Typography>
            </Grid>

            <Grid item xs={12} sm={8}>
                <TextField
                    label="Full Name"
                    name="authorization_fullname"
                    fullWidth
                    required
                    value={formValues.authorization_fullname}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "authorization_fullname",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <KeyboardDatePicker
                    id="authorization_signeddate"
                    label="Date Signed"
                    name="authorization_signeddate"
                    required
                    fullWidth
                    format="MM/dd/yyyy"
                    value={formValues.authorization_signeddate}
                    onChange={date =>
                        dispatch({
                            type: 'editFormValue',
                            key: "authorization_signeddate",
                            value: date
                        })
                    }
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </Grid>

            <Grid item xs={12} container alignContent="center" alignItems="center" justify="center">
                <SignatureForm
                    required
                    handleSignature = {handleSignature}
                />
            </Grid>

        </MuiPickersUtilsProvider>
    </>
}

export default ApplicantAuthorizationForm;