import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import commonData from '../../../../data/common.json';
import { useStateValue } from "../../../../StateContext";

const PaymentSummary = () => {

    const [{ formValues }] = useStateValue();
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    });

    var paymentTotal = 0;
    var rows = [];
    var useCode = (commonData.landlords[formValues.information_landlord_referral] ? true : false); //availableCodes.includes(formValues.information_landlord_referral);
    for (var i=0; i<formValues.services_products.length; i++) {

        var product = formValues.services_products[i];

        if (commonData.products[product]) {
            var row = {
                name: commonData.products[product].name,
                cost: commonData.products[product].cost,
                discount: 0,
                total: 0
            };

            if (useCode) {
                row.discount = commonData.products[product].discount;
            } else {
                row.discount = 0;
            }

            paymentTotal += (row.cost - row.discount);

            row.total = formatter.format(row.cost - row.discount);
            row.cost = formatter.format(row.cost);
            row.discount = formatter.format(row.discount);

            rows.push(row);
        }
    }

    /*
        <TableCell align="right">Cost</TableCell>
        <TableCell align="right">Discount</TableCell>

        <TableCell align="right">{row.cost}</TableCell>
        <TableCell align="right">{row.discount}</TableCell>

        style={{minWidth: '650px'}}
    */

    return <>
        <TableContainer component={Paper}>
            <Table aria-label="Report Summary">
                <TableHead>
                    <TableRow>
                        <TableCell>Report Name</TableCell>
                        <TableCell align="right">Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                    <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                        {row.name}
                        </TableCell>
                        <TableCell align="right">{row.total}</TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <br></br>
        Total: { formatter.format(paymentTotal) }
    </>
}

export default PaymentSummary;
