import React, { useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
    TextField,
    Grid,
    Typography,
    Button,
} from "@material-ui/core";

import { useStateValue } from "../../StateContext";
import { ReportsCallIssue } from '../../ServerCalls/ReportsCalls';

const style = makeStyles(theme => ({
    button: {
        marginRight: theme.spacing(1),
    },
    mainBox: {
        position: "relative",
        marginTop: "-8px",
        padding: "10px 20px",
        borderBottomRightRadius: "4px",
        borderBottomLeftRadius: "4px",
        background: theme.palette.background.default
    },
    stepper: {
        height: "calc(10vh - 40px)",
        minHeight: "55px"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around"
    },
    buttonWrapper: {
        justifyContent: "flex-end"
    },
}));

const dividerSection = {
    height: "25px"
};

const ReportRequest = () => {

    const [{ reportValues }, dispatch] = useStateValue();
    const [status, setStatus] = useState('default');
    const classes = style();

    const requestReport = async () => {

        const response = await ReportsCallIssue(reportValues);
        const status = response.responseData.success;
    
        if (status) {
            setStatus('success');
        } else {    
            setStatus('fail');
        }
        
    };

    const FormStatus = () => {
        if (status === 'default') {
            return (
                <>
                  <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          type="submit"
                          onClick={requestReport}
                      >
                      SUBMIT
                  </Button>
                </>
            );
        } else if (status === 'success') {
            return (
                <>
                    A link to your report has been sent to your email address.  Please check your spam and junk folders for an email from info@qualofied.com.
                </>
            );
        } else if (status === 'fail') {
            return (
                <>
                    <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            type="submit"
                            onClick={requestReport}
                        >
                        SUBMIT
                    </Button>
                    <br></br>
                    <br></br>
                    We are unable to locate a report based on the information you have provided.  Please try again or contact us at info@qualofied.com and provide your name and email address.
                </>
            );
        } else {
            return (
                <>
                    An error has occured.
                </>
            );
        }
    };

    return <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            
            <Grid item xs={12}>
                <Typography variant="h6">Request A Report</Typography>
                <Typography>To access a previous report, enter your information below.  If you need a new report, complete the application form.</Typography>
            </Grid>
            <Grid item xs={12}>
                <div style={dividerSection}></div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="First Name"
                    name="firstname"
                    disabled={(status==='success')}
                    required
                    fullWidth
                    value={reportValues.firstname}
                    onChange={e =>
                        dispatch({
                            type: 'editReportValue',
                            key: "firstname",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Last Name"
                    name="lastname"
                    disabled={(status==='success')}
                    required
                    fullWidth
                    value={reportValues.lastname}
                    onChange={e =>
                        dispatch({
                            type: 'editReportValue',
                            key: "lastname",
                            value: e.target.value
                        })
                    }
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                    id="dateofbirth"
                    label="Date of Birth"
                    name="dateofbirth"
                    required
                    disabled={(status==='success')}
                    fullWidth
                    format="MM/dd/yyyy"
                    value={reportValues.dateofbirth}
                    onChange={date =>
                        dispatch({
                            type: 'editReportValue',
                            key: "dateofbirth",
                            value: date
                        })
                    }
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Email Address"
                    name="email"
                    required
                    disabled={(status==='success')}
                    fullWidth
                    value={reportValues.email}
                    onChange={e =>
                        dispatch({
                            type: 'editReportValue',
                            key: "email",
                            value: e.target.value
                        })
                    }
                />
            </Grid>

            <Grid item xs={12}>
                <div style={dividerSection}></div>
            </Grid>

            <Grid item xs={12}>
                {FormStatus()}
            </Grid>

        </MuiPickersUtilsProvider>
    </>
}

export default ReportRequest;