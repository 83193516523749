import { getEnivornment } from '../constants/functions';
import commonData from '../data/common.json';

const env = getEnivornment();
console.log('getEnivornment', getEnivornment());
const stage = 'stage';


const setInitialState = () => {
    var initialState;
    var formValuesDefault = {
        formValues: commonData.formValuesDefault
    };
    var formValuesGood = {
        formValues: commonData.formValuesGood
    };
    var formValuesBad = {
        formValues: commonData.formValuesBad
    };

    if (env === stage) {
        const number = Math.floor(Math.random() * 10) + 1
        initialState = (number % 2 === 0) ? formValuesGood : formValuesBad;
    } else {
        initialState = formValuesDefault;
    }

    initialState.reportValues = {
        "firstname": initialState.formValues.information_applicant_firstname,
        "lastname": initialState.formValues.information_applicant_lastname,
        "dateofbirth": initialState.formValues.information_applicant_dateofbirth,
        "email": initialState.formValues.information_applicant_email
    };

    return initialState;
}

const initialState = setInitialState();

const reducer = (state, action) => {
    switch (action.type) {
        case 'editFormValue':
            state.formValues[action.key.toLowerCase()] = action.value;
            return { ...state };

        case 'editReportValue':
            state.reportValues[action.key.toLowerCase()] = action.value;
            return { ...state };

        case 'emptyFormValue':
            return {
                ...state,
                formValues: initialState.formValues
            };
        default:
    };
    return state;
};

export { initialState, reducer }