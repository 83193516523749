import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  useParams
} from "react-router-dom";

import { Container, Paper, Box, } from "@material-ui/core";
import { makeStyles, } from '@material-ui/core/styles';
import CustomizedSteppers from "./Application/Stepper";

import { useStateValue } from "../StateContext";
import commonData from '../data/common.json';

import Header from "./Header";

export default function Application() {
  let match = useRouteMatch();
  return (
    <div>
      <Header title="RENTAL APPLICATION FORM" />
        <Switch>
          <Route path={`${match.path}/:applicationCode`}>
            <ApplicationInit />
          </Route>
          <Route path={match.path}>
            <ApplicationInit />
          </Route>
        </Switch>
    </div>
  );
}

class ApplicationImpl extends React.Component {
  instance; 
  state = {};

  componentDidMount() {
    this.props.handleApplication();
  }

  render() {
      return (
          <CustomizedSteppers />
      );
  }

}

const useStyles = makeStyles(theme => ({
  boxWrapper: {
      marginBottom: "55px",
      minHeight: "calc(26vh + 260px)"
  },
  container: {
      position: "relative",
      zIndex: "1100",
      marginTop: "-95px",
      marginBottom: "45px",
  }
}));

const ApplicationInit = () => {
  const [dispatch] = useStateValue();
  let { applicationCode } = useParams();

  const handleApplication = () => {  
    if (commonData.landlords[applicationCode]) {
      let landlord = commonData.landlords[applicationCode];
      dispatch({
        type: 'editFormValue',
        key: "information_landlord_fullname",
        value: landlord.name
      });
      dispatch({
        type: 'editFormValue',
        key: "information_landlord_email",
        value: landlord.email
      });
      dispatch({
        type: 'editFormValue',
        key: "information_landlord_phonenumber",
        value: landlord.phone
      });
      dispatch({
        type: 'editFormValue',
        key: "information_landlord_referral",
        value: landlord.code
      });
    }
  };

  const classes = useStyles();

  return (
    <Box component="main" className={classes.boxWrapper}>
      <Container maxWidth="md" className={classes.container}>
        <Paper elevation={5}>
          <ApplicationImpl handleApplication={handleApplication}> </ApplicationImpl>
        </Paper>
      </Container>
    </Box>
  );
};