import React, { Component } from 'react'
import SignatureCanvas from 'react-signature-canvas';

import {
  Grid,
  Box,
  Button,
} from "@material-ui/core";

class SignatureForm extends Component {

  state = {
    trimmedDataURL: null,
    showButtonClear: false,
    showButtonSave: false,
  }

  componentDidMount() {
    this.props.handleSignature(null);
    //fromDataURL(base64String, options)
  }

  displaySignatureCanvas = () => {
    return (
        <div style={{border: "1px dotted black" }}>
          <SignatureCanvas 
            ref={(ref) => { 
              this.sigCanvas = ref ;
            }} 
            onEnd={this.onEnd}
            penColor='blue'
            backgroundColor='rgba(0,0,0,0)'
            canvasProps={{width: "420px", height: "100px", className: 'sigCanvas'}} 
          />
        </div>
    );
  }

  clear = () => {
    let _sigPad = this.sigCanvas.getSignaturePad();
    _sigPad.clear();
    this.setState({
      showButtonClear: false,
      showButtonSave: false,
    });
    this.props.handleSignature(null);
  }

  save = () => {
    let signature = this.sigCanvas.getCanvas().toDataURL('image/png');
    this.setState({
      trimmedDataURL: signature,
      showButtonClear: true,
      showButtonSave: false,
    });
    this.props.handleSignature(signature);
  }

  onEnd = () => {
    let _sigPad = this.sigCanvas.getSignaturePad();
    let isEmpty = _sigPad.isEmpty();
    this.setState({
      showButtonClear: !isEmpty,
      showButtonSave: !isEmpty,
    });    
  }

  render () {
    //let {trimmedDataURL} = this.state;
    return (
      <div>

        {this.displaySignatureCanvas()}

        <br></br>

        <Grid item xs={12} container alignContent="center" alignItems="center" justify="center">
            <Grid item>
              <Button variant="contained" color="primary" onClick={this.clear} disabled={!this.state.showButtonClear}>
                Clear Signature
              </Button>
            </Grid>
            <Box m={2}></Box>
            <Grid item>
              <Button variant="contained" color="primary" onClick={this.save} disabled={!this.state.showButtonSave}>
                Save Signature
              </Button>
            </Grid>
        </Grid>

      </div>
    );
  }

}

export default SignatureForm;

//{trimmedDataURL ? <img style={sigImage} src={trimmedDataURL} /> : null}